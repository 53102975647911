<template>
    <div class="m-index">
       <div class="banner-box"><img src="../../../assets/indexImg/mbanner.png" alt="" srcset=""></div>
          <div class="product-box">
            <h3 class="title"><span>智能工厂应用全景</span><img src="../../../assets/indexImg/mtitle-one.png" alt="" srcset=""></h3>
            <div class="in-box">
              <div class="dom-box">
                <img src="../../../assets/indexImg/m-zhanwei.png" alt="" srcset=""  >
                <div class="txt">
                  <div class="txt-item"  v-for="(item,num) in txtList" :key="num">
                    <div class="simple bgStyle">
                      <h3 class="name-box"><span class="" >{{item.name}}</span></h3>
                      <div class="ant-popover-arrow"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="case-box">
            <h3 class="title"><span>解决方案</span><img src="../../../assets/indexImg/mtitle-two.png" alt="" srcset=""></h3>
            <div class="slide-box">
                <swiper :options="swiperOption" ref="mySwiper"  class="swiper-box"  >
                  <!-- v-if="solutionList1.length>0" -->
                      <swiper-slide v-for="(item,index) in solutionList"  :key="index" style="" class="" >
                        <div class="case-box1">
                          <p>{{item.name}}</p>
                           <router-link class="btn"  :to="`/MBusinessPage/${item.id}`">了解更多<span><img src="../../../assets/indexImg/m-arrow.png" alt="" srcset=""></span></router-link>
                        </div>
                      </swiper-slide>
                       <div class=" swiper-pagination swiper-pagination1" slot="pagination"></div>
                  </swiper>
                </div>
          </div>
          <div class="new-box">
            <h3 class="title"><span>最新动态</span><img src="../../../assets/indexImg/mtitle-three.png" alt="" srcset=""></h3>
            <div class="news-info">
              <ul>
                <li v-for="(item,index) in newList" :key="item.name"  v-show="index<4">
                        <router-link  :to="`/MNewDetails/${item.newsID}`">
                          <span>
                                {{moment(item.updatedTime).format('MM/DD')}}
                          </span>
                          <h4>{{item.newsTitle}}</h4>
                          </router-link>
                      </li>
              </ul>
            </div>
          </div>
            <div class="contactUs">
              <h3 class="title"><span>产品优势</span><img src="../../../assets/indexImg/title-four.png" alt="" srcset=""></h3>
              <div class="des">
                  <p>
                    <a-icon type="check-circle"  theme="filled" />解决工厂实际痛点 <br/>
                    <a-icon type="check-circle"  theme="filled" />生产数据实时、透明，让管理、决策有据可依!<br/>
                    <a-icon type="check-circle"  theme="filled" />提高生产管理效率</p>
              </div>
              <router-link class='btn' :to="'/MExperience'">立即体验</router-link>
          </div>
        <div class="box">
        </div>
        <div class="bottom-box">
           <img class="bottom-box-img" src="../../../assets/indexImg/bottom.png" alt="" srcset="">
      </div>
    </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import  { newsImg } from '@/config/appSetting'
import { getIndex } from '@/api/data'
import moment from 'moment'
import IconFont from'@/components/Icon'
export default {
  name:'Mindex',
   components:{
       IconFont
   },
       data(){
       let that=this
        return {
            visible:false,
            productList:[],
            solutionList:[],
            newList:[],
            newsImg,
            swiperOption:{
              slidesPerView: 2,
              spaceBetween:35,
              autoplay: false,
              pagination: {
                el: '.swiper-pagination1',
                type: 'progressbar'
              },
            },
             txtList:[
              {
                name:'物料管理',
                icon:'icon-wuliaoguanli',
                txt:['物料库存数据实时监控，避免物料呆滞和浪费','库存阈值设定，水位波动快速预警，提高库存利用率','透明化管理生产物料，出入库信息自动记录，库存流转准确查询','物料、库存数据可视化分析，提升物料周转效率']
              },
              {
                name:'计划管理',
                icon:'icon-jihuaguanli',
                txt:['生产订单在线同步，快捷制定生产计划，一键下发生产任务 ','根据生产资源优化排程，提升排程效率','准确应对插单、急单等突发情况，动态调整生产计划','多维度计划分析，实时查看排程情况']
              },
              {
                name:'异常管理',
                icon:'icon-yichangguanli',
                txt:['生产、设备、库存等异常实时报警，轻松应对','准确提醒订单漏排、报工逾期、交期临近等状态，降低交付风险','所有异常情况实时记录，智能分析推送，帮助管理者提升工厂运营平稳性']
               },
               {
                name:'设备管理',
                icon:'icon-shebeiguanli',
                txt:['设备运行数据实时采集、状态变化实时监控','设备台账电子化、规范化保存，方便追溯分析','自定义维保、点检任务，周期性提醒派单，完成报告自动上传','建立设备故障履历，为故障处理人员提供方案，缩短停工时间，提高设备利用率','设备数据多维度分析，降低设备故障率，提升设备综合效率（OEE）']
              },
             
              {
                name:'追溯管理',
                icon:'icon-zhuisuguanli',
                txt:['物料、批次、质量、设备等多重追溯，清晰准确查看所有操作流程','支持正向、反向追溯，高效打造透明工厂','所有追溯数据、报告便捷管理，一键下载打印']
              },
               {
                name:'生产管理',
                icon:'icon-shengchanguanli',
                txt:['工单在线生产，生产任务准确分配，移动端快捷领取','自动或扫码报工，生产任务完成情况实时可见，提高生产效率','生产数据实时统计，可视化多维度分析，实时掌握一手车间数据']
              },
              {
                 name:'质量管理',
                icon:'icon-zhiliangguanli',
                txt:['质量控制所需数据自动在线采集，产品质量档案详细完整','质量数据采集实时性高，反向优化条件充足','质检标准灵活配置，质量数据趋势监控，超界报警','支持产品质量SPC分析，人机料法环全方位追溯']
              },
              {
                name:'环境安全',
                icon:'icon-huanjinganquan',
                txt:['数字化EHS检查表、代码参照表，量化企业环境、健康与安全指标','EHS日常巡检，安全隐患移动端拍照上传，实时检测与评价，彻底消除各种事故','基于PDCA循环原则构建持续改善机制，多样化统计分析报表、改善效果对比显而易见']
              },
               {
                 name:'商业智能',
                icon:'icon-shangyezhineng',
                txt:['打通物料、质量、设备等所有生产信息孤岛，全流程信息化，数据看板实时监控','多维度、深层次分析生产、质量、设备等数据，报表可视化呈现，落地智能工厂','高仿真三维可视化工厂模型，快捷管理各个生产模块，真正实现协同制造']
              },
            ]
        }
    },
   methods:{
     moment,
       getData(){
        let _this=this
        _this.productList=[]
        _this.solutionList=[]
        _this.newList=[]
        return getIndex().then(res => {
            if (res.state) {
                if(res.data.newsResult.length>0){
                    let newsarr=[]
                    res.data.newsResult.map((item)=>{
                         newsarr=newsarr.concat(item.newsList)
                    })
                    //按时间排序
                    _this.newList=newsarr.sort(function(a,b){
                        let result=moment(a.updatedTime).isBefore(b.updatedTime)
                        return result ? 1 : -1
                    });
                }
                if(res.data.productCaseResult.length>0){
                    res.data.productCaseResult.map((item)=>{
                        if(item.productType==1){
                            let imgUrl=item.productImageUrl?_this.newsImg+item.productImageUrl:''
                             _this.productList.push({
                                 id:item.productID,
                                 name:item.productName,
                                 txt:item.productContent,
                                 imgUrl:imgUrl
                             })
                        }
                        if(item.productType==2){
                              let imgurl=item.productImageUrl?_this.newsImg+item.productImageUrl:''
                              let logoUrl=item.productCaseImages[0]?_this.newsImg+item.productCaseImages[0].imageUrl:''
                             _this.solutionList.push({
                                 id:item.productID,
                                 name:item.productName,
                                 txt:item.productContent,
                                 imgurl:imgurl,
                                 logoUrl:logoUrl
                             })
                        }
                    })
                }
            }
        })
      }
   },
   mounted() {
    this.getData()
  },
}
</script>
<style lang="less" scoped>
.m-index{
  background: #fff;
  position: relative;
  .banner-box{
    margin-top: -1.333333rem /* 100/75 */;
    img{
      width: 100%;
      object-fit: cover;
    }
  }
  .title{
    margin-bottom: 1rem /* 75/75 */;
    margin-top: .666667rem /* 50/75 */;
      span{
        font-size:.533333rem /* 40/75 */;
        color:#1183f1;
        line-height: .666667rem /* 50/75 */;
      }
      img{
        display: block;
        margin: -.266667rem /* 20/75 */ auto 0;
        height: .533333rem /* 40/75 */;
        width: auto !important;
      }
    }   
  .product-box{
     position: relative;
    z-index: 3;
    .title{
      margin-bottom: 0;
      margin-top:0;
    }
    margin:-.533333rem /* 40/75 */ 0 0;
    overflow: hidden;
    .in-box{
      display: block;
      overflow-x: scroll;
      margin-top: -.533333rem /* 40/75 */;
      .dom-box{
        position: relative;
        width: max-content;
        .bgStyle{
          border-radius: 5px;
        }
        .txt-item{
         position: absolute;
          i{
            margin-right: .133333rem /* 10/75 */;
            font-size: .64rem /* 48/75 */;
            vertical-align:text-top;
          }
         
          .simple{
           width: 1.866667rem /* 140/75 */;
           height:.693333rem /* 52/75 */;
           line-height: .693333rem /* 52/75 */;
           font-size: .32rem /* 24/75 */;
             h3{
              position: absolute;
              z-index: 2;
              width: 100%;
              background: #4b91eb;
              color: #fff;
              font-size: .373333rem /* 28/75 */;
            }
            .ant-popover-arrow {
                position: absolute;
                display: block;
                width: .266667rem /* 20/75 */;
                height: .266667rem /* 20/75 */;
                background: transparent;
                border-style: solid;
                border-width:.133333rem /* 10/75 */;
                transform: rotate(45deg);
                border-color: transparent #4b91eb #4b91eb transparent;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
                bottom: -.093333rem /* 7/75 */;
                z-index: 1;
            }
           
          }
        }
        .txt-item:nth-child(1){
          top: 2.666667rem ;
          left: 3.2rem /* 240/75 */;
          .simple{
              .ant-popover-arrow{
                  right: -.08rem /* 6/75 */;
                  left: auto;
                  bottom: .24rem /* 18/75 */;
                  transform:rotate(315deg);
              }
            }
        }
        .txt-item:nth-child(2){
          left:2.933333rem /* 220/75 */;
          top:6.4rem /* 480/75 */;
          .simple{
            .ant-popover-arrow{
                 right: -.08rem /* 6/75 */;
                  left: auto;
                  bottom: .24rem /* 18/75 */;
                  transform:rotate(315deg);
            }
          }
        }
        .txt-item:nth-child(3){
            top:5.2rem /* 390/75 */;
            left: 6.533333rem /* 490/75 */;
          .simple{         
            .ant-popover-arrow{
                right: -.08rem /* 6/75 */;
                left: auto;
                bottom: .24rem /* 18/75 */;
                transform:rotate(315deg);
            }
          }
        }
         .txt-item:nth-child(4){
           left: 4.666667rem /* 350/75 */;
           top:8rem /* 600/75 */;
            .simple{
              .ant-popover-arrow{
                right: -.08rem /* 6/75 */;
                left: auto;
                bottom: .24rem /* 18/75 */;
                transform:rotate(315deg);
              }
            }
        }
        .txt-item:nth-child(5){
            right: 5.333333rem /* 400/75 */;
            bottom: 2.266667rem /* 170/75 */;
            .simple{
              .ant-popover-arrow{
                bottom:.24rem /* 18/75 */;
                left: -.093333rem /* 7/75 */;
                transform:rotate(-224deg);
              }
            }
        }
        .txt-item:nth-child(6){
           right: 4.8rem /* 360/75 */;
           top:7.066667rem /* 530/75 */;
            .simple{
              .ant-popover-arrow{
                 bottom:.24rem /* 18/75 */;
                left: -.093333rem /* 7/75 */;
                transform:rotate(-224deg);
              }
            }
        }
        .txt-item:nth-child(7){
          right:2.666667rem /* 200/75 */;
          bottom: 8rem /* 600/75 */;
            .simple{
              .ant-popover-arrow{
                bottom:.24rem /* 18/75 */;
                left: -.093333rem /* 7/75 */;
                transform:rotate(-224deg);
              }
            }
        }
        .txt-item:nth-child(8){
            left:10.666667rem /* 800/75 */;
            top: 3.533333rem ;
            .simple{
              .ant-popover-arrow{
                 bottom:.24rem /* 18/75 */;
                left: -.093333rem /* 7/75 */;
                transform:rotate(-224deg);
              }
            }
        }
        .txt-item:nth-child(9){
            left: 9.333333rem /* 700/75 */;
            top: 1.866667rem /* 140/75 */;
        }
      
      }
    }
    img{
      width: 21.333333rem /* 1600/75 */;
    }
  }
  .case-box{
    position: relative;
    z-index: 3;
    padding:0 .533333rem /* 40/75 */;
    margin-bottom:1.6rem /* 120/75 */;
    .slide-box{
      .swiper-container{
       padding-bottom: 1.333333rem /* 100/75 */;
      }
      .swiper-slide{
       .case-box1{
        height:4.933333rem /* 370/75 */;
        background-color: #0f76ef;
        border-radius: 10px;
        padding: .666667rem /* 50/75 */ .4rem /* 30/75 */ 0;
        background-image: url(../../../assets/indexImg/m-case-bg.png);
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 40%;
        p{
          color:#fff;
          font-size: .346667rem /* 26/75 */;
          text-align: left;
          height: 1.066667rem /* 80/75 */;
          overflow: hidden;
        }
        a{
          float: right;
          color:#fff;
          text-align: right;
          font-size: .32rem /* 24/75 */;
          margin-top: 1.333333rem /* 100/75 */;
          span{
            margin-left: .2rem /* 15/75 */;
            vertical-align: initial;
            img{
              width: 22%;
            }
          }
        }
       }
      }
    }
  }
  .new-box{
    padding:0 .533333rem /* 40/75 */;
    margin-bottom: .666667rem /* 50/75 */;
    position: relative;
       z-index: 3;
    .news-info{
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px  rgba(18, 98, 224, 0.3);
      border-radius: 10px;
      ul{
        overflow: hidden;
        list-style: none;
        padding: .266667rem /* 20/75 */ 0;

        li{
          text-align: left;
          a{
            display: block;
            padding:.2rem /* 15/75 */ 0 ;
            border-bottom: 1px solid #dddddd;
            margin: 0 .266667rem /* 20/75 */;
            h4{
              font-size: .373333rem /* 28/75 */;
              height:1.066667rem /* 80/75 */;
              color:#333;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              padding-left: .4rem /* 30/75 */;
            }
            span{
              float: left;
              line-height: .72rem /* 54/75 */;
              font-size: .32rem /* 24/75 */;
              text-indent: .133333rem /* 10/75 */;
              color:#fff;
              background-color: #2281f1;
              border-radius: 0px 8px 2px 0px;
              width: 1.266667rem /* 95/75 */;
              height: .72rem /* 54/75 */;
              margin-left: -.266667rem /* 20/75 */;
              margin-top: .2rem /* 15/75 */;
            }
          }
           &:last-child{
            a{
             border-bottom:0;
            }
          }
        }
      }
    }
  }
  .contactUs{
    overflow: hidden;
    border-bottom: solid 1px #ffffff;
    margin:0 .533333rem /* 40/75 */;
    position: relative;
    z-index: 3;
    .title{
      span{
        color:#fff;
      }
    }
      p{
        font-size: .373333rem /* 28/75 */;
        color:#fff;
        text-align: left;
        line-height: 2;
        i{
          margin-right: .133333rem /* 10/75 */;
        }
      }
      .btn{
        display: block;
        width: 2.133333rem /* 160/75 */;
        padding:.106667rem /* 8/75 */ 0;
        margin:1rem auto 1rem /* 75/75 */;
        border: solid 1px #ffffff;
        color:#fff;
        font-size: .32rem /* 24/75 */;
        border-radius: 4px;
      }
  }
   .box {
        width: 100%;
        height:6.6rem /* 75 */;
        transform: skewY(-30deg);
        margin-top: -8rem /* 600/75 */;
        position: relative;
        z-index: 2;
        background: #ffff;
        margin-top:-16rem /* 1200/75 */;
    }
   .bottom-box{
     margin-top: -3.733333rem /* 280/75 */;
        width: 100%;
        height:22.666667rem /* 1700/75 */;
        position: relative;
        z-index: 1;
        background-image: -webkit-linear-gradient(-136deg, #1183f1,#0953cf);
        background: -ms-linear-gradient(-136deg, #1183f1,#0953cf);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#0953cf, endColorstr=#1183f1);/*IE<9>*/
        -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=1, startColorstr=#0953cf, endColorstr=#1183f1)";/*IE8+*/
        position: relative;
        .bottom-box-img{
            width: auto;
            height: 100%;
            position: absolute;
            right: -40%;
            bottom: 0;
        }
    }
}

</style>
<style>
@-webkit-keyframes fadeInUpa {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpa {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUpa {
  -webkit-animation-name: fadeInUpa;
  animation-name: fadeInUpa;
}

.swiper-pagination1 {
    top: auto !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin:0 auto !important;
    width: 4rem /* 300/75 */!important;
    height: 6px !important;
    background-color: #c9d2e1 !important;
    border-radius: 3px !important;
    overflow: hidden;
   
}
</style>

